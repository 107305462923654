import { createSlice } from '@reduxjs/toolkit'

const initialState = []

const usersSlice = createSlice({
	name: 'users',
	initialState,
	reducers: {
		addUsers: (state, action) => {
			state.push(action.payload)
		},
		getAllUsers: (state, action) => {
			return action.payload
		},
	},
})

export const { addUsers, getAllUsers } = usersSlice.actions
export default usersSlice.reducer
