import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	inforUser: {},
	token: '',
	isLogged: false,
	isAdmin: false,
}

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		login: (state, action) => {
			state.token = action.payload.token
			state.isLogged = true
		},
		getToken: (state, action) => {
			state.token = action.payload
			state.isLogged = true
		},
		getUser: (state, action) => {
			state.inforUser = action.payload
			state.isAdmin = action.payload.role === 0 ? true : false
		},
		logout: (state) => {
			state.token = ''
			state.isLogged = false
			state.isAdmin = false
			state.inforUser = {}
		},
	},
})

export const { login, getToken, getUser, logout } = authSlice.actions
export default authSlice.reducer
