import { createSlice } from '@reduxjs/toolkit'

const initialState = []

const taskSlice = createSlice({
	name: 'task',
	initialState,
	reducers: {
		addTask: (state, action) => {
			state.push(action.payload)
		},
		getAllTasks: (state, action) => {
			state = action.payload
		},
	},
})

export const { addTask, getAllTasks } = taskSlice.actions
export default taskSlice.reducer
