import { createSlice } from '@reduxjs/toolkit'

const initialState = []

const softwareOffersSlice = createSlice({
	name: 'softwareOffer',
	initialState,
	reducers: {
		addSoftwareOffer: (state, action) => {
			state.push(action.payload)
		},
		getAllSoftwareOffer: (state, action) => {
			return action.payload
		},
	},
})

export const { addSoftwareOffer, getAllSoftwareOffer } =
	softwareOffersSlice.actions
export default softwareOffersSlice.reducer
