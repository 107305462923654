import { createSlice } from '@reduxjs/toolkit'

const initialState = []

const topupSlice = createSlice({
	name: 'topup',
	initialState,
	reducers: {
		addTopup: (state, action) => {
			return action.payload
		},
	},
})

export const { addTopup } = topupSlice.actions
export default topupSlice.reducer
