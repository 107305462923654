import { configureStore } from '@reduxjs/toolkit'
import authSlice from '~/redux/toolkits/authSlice'
import tasksSlice from '~/redux/toolkits/tasksSlice'
import topupSlice from '~/redux/toolkits/topupSlice'
import softwaresSlice from '~/redux/toolkits/softwaresSlice'
import softwareOffersSlice from '~/redux/toolkits/softwareOffersSlice'
import usersSlice from '~/redux/toolkits/usersSlice'
import historyTransactionSlice from '~/redux/toolkits/historyTransactionSlice'
import historyMomoSlice from '~/redux/toolkits/historyMomoSlice'
import historyBankSlice from '~/redux/toolkits/historyBankSlice'

export const store = configureStore({
	reducer: {
		auth: authSlice,
		tasks: tasksSlice,
		topup: topupSlice,
		softwares: softwaresSlice,
		softwareOffers: softwareOffersSlice,
		users: usersSlice,
		historyTransaction: historyTransactionSlice,
		historyMomo: historyMomoSlice,
		historyBank: historyBankSlice,
	},
})
