import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'

import './index.css'
import GlobalStyle from '~/components/GlobalStyles'
import { store } from '~/redux/store'
import { Provider } from 'react-redux'
import Loading from './components/Layout/components/Loading'

// lazy load app
const App = React.lazy(() => import('~/App'))

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
	<Suspense fallback={<Loading />}>
		<Provider store={store}>
			<GlobalStyle>
				<App />
			</GlobalStyle>
		</Provider>
	</Suspense>
)
