import { Spin, Space } from 'antd'

const Loading = () => {
	return (
		<>
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					marginTop: '2rem',
				}}
			>
				<Space>
					<Spin size='large' />
					<Spin size='large' />
					<Spin size='large' />
				</Space>
			</div>
		</>
	)
}

export default Loading
